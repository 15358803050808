@charset "utf-8";
/* CSS Document */

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

body {
  margin: 0;
  padding: 0;
  font-family: "Mulish", sans-serif !important;
  font-size: 14px;
  color: #3a3a3a;
  font-weight: 400;
  position: relative;
}

* {
  margin: 0px;
  padding: 0px;
}

.clearfix {
  zoom: 1;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}

.clearfix:after {
  clear: both;
}

.clr,
.clear {
  clear: both;
}

header,
footer,
aside,
article,
section,
nav,
figure {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul,
li,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  outline: none !important;
  cursor: pointer;
}

a:hover,
a:focus {
  text-decoration: none !important;
  outline: none;
}

img {
  border: 0;
  outline: none;
  max-width: 100%;
}

input[type="text"],
input[type="password"],
select,
textarea,
input[type="button"] {
  max-width: 100%;
}

table {
  width: 100%;
}
p {
  margin: 0;
  padding: 0;
}
.fltL {
  float: left;
}
.fltR {
  float: right !important;
}
.padding_none {
  padding: 0 !important;
}
.border_none {
  border: none !important;
}
.navbar-toggle {
  background-color: #fff !important;
}
.icon-bar {
  background-color: #000 !important;
}
.p_right0 {
  padding-right: 0px !important;
}
.p_left0 {
  padding-left: 0px !important;
}
.margin0 {
  margin: 0 !important;
}
input[type="date"].form-control {
  text-transform: uppercase !important;
}
select {
  cursor: pointer;
  outline: 0;
}

.Button {
  border: none;
  padding: 10px 25px;
  text-align: center;
  transition: 0.5s all ease-in-out;
  font-weight: 700;
  position: relative;
  font-size: 15px;
  color: #fff;
  text-transform: capitalize;
  border-radius: 5px;
  outline: 0 !important;
  box-shadow: none;
  background: #17aab4;
  background-image: linear-gradient(45deg, #17aab4, #064448);
}
.Button:hover {
  background-position: 0px 80px;
  color: #fff;
}
.Button.Cancel {
  background: #b1b1b1;
  color: #000;
  margin: 0 0 0 0;
  padding: 10px 16px;
}

.CheckBox {
  display: inline-block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 600;
  color: #2d2d2d;
  margin: 0;
  min-height: 20px;
}
.CheckBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.CheckBox .checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 20px;
  width: 24px;
  background-color: transparent;
  border: 1px solid #006ce0;
  border-radius: 2px;
}
.CheckBox:hover input ~ .checkmark {
  background-color: #f56f05;
  border-color: #f56f05;
}
.CheckBox input:checked ~ .checkmark {
  background-color: #f56f05;
}
.CheckBox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 8px;
  top: 2px;
  width: 5px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.CheckBox input:checked ~ .checkmark:after {
  display: block;
}

.switch {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0;
  padding: 2px 0 3px 50px;
  font-weight: 400;
  font-size: 12px;
  color: #646467;
  float: right;
  color: green;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 21px;
  border-radius: 50px;
  width: 40px;
  border: 1px solid #d2c5e0;
  min-width: auto;
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 3px;
  bottom: 0;
  top: 0;
  background-image: linear-gradient(45deg, #00aab5, #639edd);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  margin: auto;
}
.switch input:checked + .slider {
  background-image: linear-gradient(45deg, #00aab5, #639edd);
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  transform: translateX(19px);
  background: #fff;
}

.LoginArea {
  min-height: 100vh;
  background: rgb(243 131 14 / 6%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.LoginArea .LoginBox {
  display: flex;
  width: 80%;
  box-shadow: 0 10px 30px #f4851329;
  align-items: center;
}
.LoginArea .LoginBox .LoginLeft {
  background-color: #fff;
  width: 50%;
  padding: 100px 50px;
}
.LoginArea .LoginBox .LoginLeft h2 {
  margin: 0 0 15px 0;
  text-align: center;
  font-weight: 800;
  font-size: 30px;
  color: #15aab5;
  letter-spacing: 1px;
}
.LoginArea .LoginBox .LoginLeft h2 span {
  color: #f48e27;
}
.LoginArea .LoginBox .LoginLeft h3 {
  text-align: center;
  margin: 0 0 20px 0;
  font-size: 16px;
  font-weight: 700;
}
.LoginArea .LoginBox .LoginLeft h3 span {
  color: #17aab5;
  border-bottom: 2px solid #f38406;
  padding: 0 0 6px 0;
  display: inline-block;
}
.LoginArea .LoginBox .LoginLeft p {
  font-size: 14px;
  text-align: center;
  margin-bottom: 12px;
}
.LoginArea .LoginBox .LoginLeft h6 {
  text-align: center;
  margin: 0 0 20px 0;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  line-height: 23px;
}
.LoginArea .LoginBox .LoginLeft .form-group {
  position: relative;
  margin: 0 0 25px 0;
}
.LoginArea .LoginBox .LoginLeft .form-group label {
  font-weight: 600;
  color: #17222c;
  font-size: 14px;
  display: block;
}
.LoginArea .LoginBox .LoginLeft .form-group label sup {
  color: #cc0909;
  font-size: 18px;
  top: -2px;
}
.LoginArea .LoginBox .LoginLeft .form-group .form-control {
  box-shadow: none;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  height: 40px;
  padding: 0 20px 0 50px;
  font-size: 14px;
  font-weight: 500;
}
.LoginArea .LoginBox .LoginLeft .form-group .Icon {
  width: 34px;
  height: 34px;
  background-color: #d4d4d4;
  text-align: center;
  line-height: 34px;
  border-radius: 4px;
  position: absolute;
  left: 3px;
  z-index: 100;
  top: 32px;
  color: #000;
}
.LoginArea .LoginBox .LoginLeft .form-group .OTPBox {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px 0;
}
.LoginArea .LoginBox .LoginLeft .form-group .OTPBox .form-control {
  padding: 0;
  text-align: center;
  width: 70px;
  height: 65px;
  font-size: 22px;
  font-weight: 600;
  color: #000;
  line-height: 30px;
}
.LoginArea .LoginBox .LoginLeft .Checkboxs {
  margin: 0 0 20px 0;
}
.LoginArea .LoginBox .LoginLeft .Checkboxs a {
  color: #525252;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0;
  float: right;
}
.LoginArea .LoginBox .LoginLeft .Checkboxs a:hover {
  color: #006ce0;
}
.LoginArea .LoginBox .LoginLeft .Button {
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.LoginArea .LoginBox .LoginRight {
  background-color: #f4830a00;
  width: 50%;
}
.LoginArea .LoginBox .LoginRight figure {
  width: 180px;
  margin: 0 auto 20px;
  overflow: hidden;
}
.LoginArea .LoginBox .LoginRight h5 {
  text-align: center;
  margin: 0 0 10px;
  font-size: 25px;
  font-weight: 800;
  color: #000;
  line-height: 38px;
}
.LoginArea .LoginBox .LoginRight h5 span {
  display: block;
  color: #34b7c0;
  font-size: 22px;
  letter-spacing: 2px;
}
.LoginArea .LoginBox .LoginRight h6 {
  text-align: center;
  font-size: 14px;
  color: #565656;
  font-weight: 600;
  line-height: 19px;
}

.SuccessArea {
  background: rgb(35 109 147 / 20%);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0;
}
.SuccessArea .SuccessBox {
  background-color: #fff;
  min-width: 525px;
  text-align: center;
  padding: 60px 50px;
  border-radius: 20px;
  box-shadow: 0 10px 30px #1e3e6a;
}
.SuccessArea .SuccessBox span {
  width: 100px;
  display: block;
  margin: 0 auto 10px;
}
.SuccessArea .SuccessBox h3 {
  font-size: 36px;
  font-weight: 800;
  margin: 0 0 10px 0;
  color: #000;
}
.SuccessArea .SuccessBox p {
  font-size: 15px;
  font-weight: 600;
  margin: 0 0 20px 0;
}
.SuccessArea .SuccessBox a {
  display: inline-block;
  padding: 12px 30px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  background: #d6161b;
}

.Header {
  box-shadow: 0 2px 4px #000;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 99;
}
.Header .Logo {
    width: 235px;
    text-align: center;
    padding: 10px 0;
    float: left;
    height: 40px;
    box-shadow: 0 2px 4pxrgba(0,0,0,.08);
    background-color: #fff;
}
.Header .Logo img {
  width: 75px;
}
.Header .Navigation {
    transition: all 0.2s ease;
    margin-left: 235px;
    padding: 0px 10px;
    width: calc(100% - 265px);
    text-align: right;
    height: 60px;
    background-image: linear-gradient(45deg,#00aab5,#17aab4);
    display: flex;
    align-items: center;
    justify-content: end;
}
.Header .Navigation .Bell {
  display: inline-block;
}
.Header .Navigation .Bell a {
  color: #fff;
  font-size: 23px;
}
.Header .Navigation .Avater {
  margin: 0px 0 0px 30px;
  position: relative;
  display: inline-block;
}
.Header .Navigation .Avater a {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;
  padding: 13px 0;
}
.Header .Navigation .Avater a figure {
  width: 35px;
  border-radius: 50%;
  margin: 0 15px 0 0;
  overflow: hidden;
  height: 35px;
  border: 2px solid #fff;
  background-color: white;
  padding-top: 4px;
}
.Header .Navigation .Avater ul {
  position: absolute;
  background: #fff;
  box-shadow: 0 3px 11px 0 rgb(0 0 0 / 10%);
  -webkit-transform: perspective(600px) rotateX(-90deg);
  transform: perspective(600px) rotateX(-90deg);
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 99;
  top: 100%;
  text-align: left;
  min-width: 200px;
  display: block;
  width: auto;
}
.Header .Navigation .Avater ul li {
  margin: 0;
  border-bottom: 1px solid #d2d3d4;
}
.Header .Navigation .Avater ul li:first-child {
  background-image: linear-gradient(45deg, #17aab4, #17aab4);
  position: relative;
  padding: 15px 10px 15px 60px;
  text-align: left;
  border-bottom: 1px solid #fff;
}
.Header .Navigation .Avater ul li figure {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 15px;
  border: 2px solid #fff;
  overflow: hidden;
  margin: 0;
  background-color: white;
  padding-top: 4px;
}
.Header .Navigation .Avater ul li h4 {
  color: #fff;
  font-size: 17px;
  line-height: 18px;
}
.Header .Navigation .Avater ul li h4 span {
  font-size: 11px;
  font-weight: 400;
}
.Header .Navigation .Avater ul li a {
  color: #4e4e4e;
  padding: 10px 20px;
  font-weight: 400;
  transition: 0.5s all ease-in-out;
}
.Header .Navigation .Avater ul li a span {
  margin: 0 10px 0 0;
}
.Header .Navigation .Avater ul li a:hover {
  background-image: linear-gradient(45deg, #17aab4, #17aab4);
  color: #fff;
}
.Header .Navigation .Avater:hover ul {
  opacity: 1;
  visibility: visible;
  transform: perspective(600px) rotateX(0deg);
}

.SidenavBar {
  height: calc(100% - 60px);
  position: fixed;
  width: 235px;
  top: 60px;
  padding: 0;
  overflow: auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.SidenavBar::-webkit-scrollbar-track {
  background: #bbb;
  border-radius: 0;
}
.SidenavBar::-webkit-scrollbar-thumb {
  background-image: linear-gradient(45deg, #17aab4, #17aab4);
  border-radius: 0;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
}
.SidenavBar::-webkit-scrollbar {
  width: 7px;
  border-radius: 0;
}
.SidenavBar ul {
  margin: 0;
}
.SidenavBar ul li {
  margin: 0;
}
.SidenavBar ul li a {
  display: block;
  cursor: pointer;
  padding: 13px 20px 13px 45px;
  transition: 0.5s all ease-in-out;
  color: #484848;
  font-family: "Roboto";
  font-size: 14px;
  text-transform: capitalize;
  position: relative;
  border-bottom: 1px solid #ddd;
}
.SidenavBar ul li a span {
  font-size: 15px;
  display: inline-block;
  position: absolute;
  top: 13px;
  left: 20px;
}
.SidenavBar ul li.active a,
.SidenavBar ul li a:hover {
  background-image: linear-gradient(45deg, #00aab5, #17aab4);
  color: #fff !important;
}

.SidenavBar ul li ol {
  position: relative !important;
  float: none;
  transform: inherit !important;
  border: none;
  margin: 0;
  padding: 0;
}
.SidenavBar ul li.active ol {
  display: block;
}
.SidenavBar ul li.active ol li a {
  background: transparent;
  color: #000 !important;
}
.SidenavBar ul li.active ol li a:hover {
  color: #17aab4 !important;
}

.WrapperArea {
  margin-left: 235px;
  flex: 1 1 auto;
  width: calc(100% - 235px);
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  transition: 0.5s all ease-in-out;
}
.WrapperArea .WrapperBox {
  padding: 20px;
  background-color: #efefef;
  min-height: calc(100vh - 60px);
}
.Small-Wrapper {
  background-color: #fff;
  padding: 25px;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
}
.Small-Wrapper + .Small-Wrapper {
  margin-top: 20px;
}

.TitleBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 15px 0;
}

h4.Title {
  margin: 0;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 22px;
  font-family: "Roboto";
  background-image: linear-gradient(45deg, #17aab4, #0a6066);
  background-color: transparent;
  background-clip: text;
  -o-background-clip: text;
  -ms-background-clip: text;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  -o-text-fill-color: transparent;
  -ms-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(45deg, #00aab5, #064448);
}
a.TitleLink {
  background-image: linear-gradient(45deg, #00aab5, #064448);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
}
a.TitleLink:hover {
  background-color: #04bad2;
}

.Filter {
  display: flex;
}
.Filter .form-group {
  margin: 0 10px 20px 0;
  display: inline-block;
  position: relative;
}
.Filter .form-group:last-child {
  margin: 0 0 20px 0;
}
.Filter .form-group label {
  display: block;
  font-weight: 700;
  font-size: 15px;
  color: #000;
}
.Filter .form-group .form-control {
  height: 42px;
  line-height: 25px;
  padding: 8px 10px 8px 10px;
  border: 1px solid #cbcaca;
  font-size: 14px;
  margin: 0 10px 0 0;
  box-shadow: none;
  color: #3e3d3d;
  font-weight: 600;
  border-radius: 5px;
  appearance: auto;
}

.Filter .form-group span {
  position: absolute;
  top: 42px;
  left: 15px;
}
.Filter1 .form-group .form-control {
  padding: 0px 0px 0px 40px;
}
.DashboardBox ul {
  display: flex;
  flex-wrap: wrap;
}
.DashboardBox ul li {
  width: 33%;
  padding: 20px 10px;
  margin: 30px 0 20px 0;
}
.DashboardBox ul li aside {
  position: relative;
  padding: 60px 40px;
  border: 1px solid #ddd;
  border-radius: 5px;
  min-height: 180px;
  height: 100%;
}
.DashboardBox ul li aside:before {
  content: "";
  position: absolute;
  width: 65px;
  height: 65px;
  background-color: rgb(255 255 255 / 50%);
  top: -10px;
  right: -10px;
}
.DashboardBox ul li aside:after {
  content: "";
  position: absolute;
  width: 65px;
  height: 65px;
  background-color: rgb(255 255 255 / 50%);
  bottom: -10px;
  left: -10px;
}
.DashboardBox ul li aside .Icon {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -26px;
  background-color: red;
  text-align: center;
  line-height: 50px;
  font-size: 23px;
  border-radius: 5px;
  color: #fff;
}
.DashboardBox ul li aside h3 {
  float: right;
  font-size: 40px;
  font-weight: 700;
  color: #000;
  position: relative;
}
.DashboardBox ul li aside p {
  font-size: 15px;
  font-weight: 700;
  margin: 15px 0 0 0;
  position: relative;
  z-index: 1;
  color: black;
}

.DashboardBox ul li:nth-child(1) aside {
  background-color: rgb(48 65 129 / 20%);
  border-color: #304181;
}
.DashboardBox ul li:nth-child(2) aside {
  background-color: hsl(133deg 99% 27% / 20%);
  border-color: #01891e;
}
.DashboardBox ul li:nth-child(3) aside {
  background-color: hsl(313deg 97% 40% / 20%);
  border-color: #c7039d;
}
.DashboardBox ul li:nth-child(4) aside {
  background-color: rgb(1 169 161 / 20%);
  border-color: #01a9a1;
}
.DashboardBox ul li:nth-child(5) aside {
  background-color: rgb(161 126 0 / 20%);
  border-color: #a17e00;
}
.DashboardBox ul li:nth-child(6) aside {
  background-color: rgb(1 131 181 / 20%);
  border-color: #0183b5;
}

.DashboardBox ul li:nth-child(1) aside .Icon {
  background-color: #304181;
}
.DashboardBox ul li:nth-child(2) aside .Icon {
  background-color: #01891e;
}
.DashboardBox ul li:nth-child(3) aside .Icon {
  background-color: #c7039d;
}
.DashboardBox ul li:nth-child(4) aside .Icon {
  background-color: #01a9a1;
}
.DashboardBox ul li:nth-child(5) aside .Icon {
  background-color: #a17e00;
}
.DashboardBox ul li:nth-child(6) aside .Icon {
  background-color: #0183b5;
}

.DashboardBox ul li:nth-child(1) aside h3 {
  color: #304181;
}
.DashboardBox ul li:nth-child(2) aside h3 {
  color: #01891e;
}
.DashboardBox ul li:nth-child(3) aside h3 {
  color: #c7039d;
}
.DashboardBox ul li:nth-child(4) aside h3 {
  color: #01a9a1;
}
.DashboardBox ul li:nth-child(5) aside h3 {
  color: #a17e00;
}
.DashboardBox ul li:nth-child(6) aside h3 {
  color: #0183b5;
}

.DashboardBox ul.Revenue li:nth-child(1) {
  order: 4;
}
.DashboardBox ul.Revenue li:nth-child(2) {
  order: 1;
}
.DashboardBox ul.Revenue li:nth-child(3) {
  order: 2;
}
.DashboardBox ul.Revenue li:nth-child(4) {
  order: 3;
}

.DashboardBox ul.Order li:nth-child(1) {
  order: 3;
}
.DashboardBox ul.Order li:nth-child(2) {
  order: 4;
}
.DashboardBox ul.Order li:nth-child(3) {
  order: 1;
}
.DashboardBox ul.Order li:nth-child(4) {
  order: 2;
}

.DashboardBox ul.Transactions li:nth-child(1) {
  order: 2;
}
.DashboardBox ul.Transactions li:nth-child(2) {
  order: 3;
}
.DashboardBox ul.Transactions li:nth-child(3) {
  order: 4;
}
.DashboardBox ul.Transactions li:nth-child(4) {
  order: 1;
}

.DashboardBox2 ul li {
  width: 30%;
}

.ScoringCountList ul {
  display: flex;
  flex-wrap: wrap;
}
.ScoringCountList ul li {
  width: 48%;
  margin: 0 15px 15px 0;
}
.ScoringCountList ul li:nth-child(3n) {
  margin: 0 0 15px 0;
}
.ScoringCountList ul li a {
  background-color: #fbfbfb;
  border: 1px solid #ddd;
  padding: 60px 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: #000;
  width: 100%;
  position: relative;
}
.ScoringCountList ul li a .Icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
}
.ScoringCountList ul li a .Icon:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #17aab452, transparent);
  border-radius: 5px;
  transform: rotate(45deg);
  top: 0;
  left: 0;
}
.ScoringCountList ul li a .Icon img {
  position: relative;
  z-index: 1;
}
.ScoringCountList ul li a .Text {
  font-size: 15px;
  font-weight: 600;
  margin: 0 0 0 30px;
}
.ScoringCountList ul li a .Count {
  float: right;
  position: absolute;
  right: 20px;
  font-size: 20px;
  font-weight: 800;
}
.ScoringCountList ul li a .Badge {
  width: 30px;
  height: 30px;
  background-color: #df0000;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: 32px;
  left: 65px;
}

.DriverDeleteBox {
  background-color: #fbfbfb;
  border: 1px solid #ddd;
  padding: 25px 20px 25px 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: #000;
  position: relative;
  float: right;
  min-width: 325px;
}
.DriverDeleteBox .Icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
}
.DriverDeleteBox .Icon:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, #4968a6, transparent);
  border-radius: 5px;
  transform: rotate(45deg);
  top: 0;
  left: 0;
}
.DriverDeleteBox .Icon img {
  position: relative;
  z-index: 1;
}
.DriverDeleteBox .Text {
  font-size: 15px;
  font-weight: 600;
  margin: 0 0 0 30px;
}
.DriverDeleteBox .Count {
  float: right;
  position: absolute;
  right: 20px;
  font-size: 18px;
  font-weight: 700;
}

.TableList {
  overflow: auto;
  text-align: center;
  width: 100%;
}
.TableList table {
  border: 1px solid #dcdcdc;
}
.TableList table tr:nth-child(odd) {
  background-color: #f5f2f2;
}
.TableList table tr:hover {
  background: #c7c7c7;
}
.TableList table tr th {
  background-color: #dcdcdc;
  font-weight: 700;
  text-transform: capitalize;
  color: #000;
  font-size: 14px;
  padding: 10px 10px;
}
.TableList table tr td {
  font-weight: 600;
  color: #000;
  padding: 10px 10px;
  font-size: 12px;
}
.TableList table tr td a:hover {
  color: #000;
}
.TableList table tr th:last-child,
.TableList table tr td:last-child {
  text-align: center;
  min-width: 105px;
}
.TableList table tr td:last-child a {
  color: #000;
  margin: 0 5px;
  font-size: 14px;
  display: inline-block;
}
.TableList table tr td:last-child a:hover {
  color: #000;
}
.TableList table tr td span {
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  width: auto;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  min-width: 100px;
  cursor: pointer;
}
.TableList table tr td .Actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  text-align: center;
}

.TableList table tr td a.Green {
  color: #37a713;
}
.TableList table tr td a.Red {
  color: #e21818;
}
.TableList table tr td a.Blue {
  color: #00aab5;
}
.TableList table tr td a.Yellow {
  color: #feca05;
}
.TableList table tr td a.Pink {
  color: #fd546d;
}

.TableList table tr td span.Green {
  background-color: #37a713;
}
.TableList table tr td span.Blue {
  background-color: #b88de7;
}
.TableList table tr td span.Red {
  background-color: #bb0f0f;
}
.TableList table tr td span.Orange {
  background-color: #df9919;
}

.TableList table tr td aside a.Green1 {
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  width: auto;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  min-width: 100px;
  background: #37a713;
}
.TableList table tr td aside a.Red1 {
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  width: auto;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  min-width: 100px;
  background: #bb0f0f;
}

.TopScoringList {
  display: flex;
  justify-content: space-between;
}
.TopScoringList .TableList {
  width: 49%;
}

.user-panding {
  text-align: right;
  margin-bottom: 10px;
}
.user-panding a {
  margin: 0px 0px 0px 10px;
}

.user-panding span.numbering {
  background: #eb0e0e;
  display: inline-block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 12px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.pagination a {
  color: black;
  float: left;
  padding: 2px 6px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  margin: 0px 5px 0px 5px;
}
.pagination a.active {
  background-color: #00aab5;
  color: white;
  border-radius: 5px;
}
.pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

.TableList table tr td a.view {
  color: black;
  text-decoration: underline;
  font-size: 13px;
}

.BusinessPersonal {
  background-color: #ffffff;
  margin: 0 0 20px 0;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.BusinessPersonal figure {
  width: 90px;
  height: 90%;
  border-radius: 50%;
  overflow: hidden;
  margin: 0;
  border: 3px solid #fff;
  box-shadow: 0 0 5px #c1c0c0;
}
.BusinessPersonal figcaption {
  width: calc(100% - 200px);
  padding: 0 0 0 15px;
}
.BusinessPersonal figcaption h3 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin: 0 0 7px 0;
}
.BusinessPersonal figcaption h3 span {
  font-size: 15px;
  padding: 0 0 0 20px;
  position: relative;
  margin: 0 0 0 10px;
  color: #cb9905;
  font-weight: 500;
}
.BusinessPersonal figcaption h3 span:before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 10px;
  left: 0;
  top: 7px;
}
.BusinessPersonal figcaption p {
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
}
.BusinessPersonal .Actions {
  display: flex;
  align-items: baseline;
}
.BusinessPersonal .Actions a {
  margin: 0 0 0 10px;
  display: inline-block;
  width: 25px;
  text-align: center;
  line-height: 25px;
}
.BusinessPersonal .Actions a.Green {
  color: #37a713;
}
.BusinessPersonal .Actions a.Red {
  color: #e21818;
}

.BusinessPersonal .Actions a.Button {
  margin: 0 0 0 10px;
  text-align: center;
  line-height: 25px;
  display: flex;
  width: 100px;
  align-items: center;
}
.BusinessPersonal .Actions a.Button .fa {
  margin: 0px 0px 0px 10px;
}
.BusinessPersonal2 figure {
  width: 160px;
  border-radius: 10px;
}
.form-group1 label {
  width: 250px;
}
.CheckBox {
  display: inline-block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 600 !important;
  color: #2d2d2d;
  margin: 0 !important;
  min-height: 20px;
}
.CheckBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.CheckBox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 24px;
  background-color: transparent;
  border: 1px solid #d2c5e0;
  border-radius: 2px;
}
.CheckBox:hover input ~ .checkmark {
  background: linear-gradient(45deg, #1e3f6b, #577fb3);
  border-color: #5682bb;
}
.CheckBox input:checked ~ .checkmark {
  background: linear-gradient(45deg, #1e3f6b, #5286ca);
}
.CheckBox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 8px;
  top: 2px;
  width: 5px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.CheckBox input:checked ~ .checkmark:after {
  display: block;
}
.form-group1 {
  margin: 20px;
}

.CommonForm h4 {
  font-size: 16px;
  padding: 15px 20px;
  background-color: #f1efef;
  font-weight: 700;
  color: #000;
  margin: 0 0 20px 0px;
}
.CommonForm .form-group {
  position: relative;
  margin: 0 0 20px 0;
}
.CommonForm .form-group:last-child {
  padding: 0;
  border: none;
}
.CommonForm .form-group label {
  display: block;
  color: #000;
  font-weight: 700;
  font-size: 14px;
  text-transform: capitalize;
  margin: 0 0 10px 0;
}
.CommonForm .form-group .form-control {
  height: auto;
  padding: 10px 10px 10px 15px;
  border: 2px solid #ededed;
  border-radius: 5px;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
}
.CommonForm .form-group .ck.ck-editor {
  margin: 0 0 20px 0;
  border: 2px solid #ededed;
  border-radius: 8px;
  overflow: hidden;
}
.CommonForm .form-group .ck.ck-label.ck-voice-label {
  display: none;
}
.CommonForm .form-group .ck.ck-editor .ck-toolbar {
  border: none;
  border-bottom: 2px solid #ededed;
}
.CommonForm .form-group .ck.ck-content.ck-editor__editable {
  padding: 10px;
  min-height: 215px;
  border: none;
  box-shadow: none;
}

.AnchorArea {
}
.AnchorArea .form-group {
  margin: 0 0 20px 0;
  position: relative;
}
.AnchorArea .form-group label {
  display: block;
  color: #000;
  font-weight: 700;
  font-size: 14px;
  text-transform: capitalize;
  margin: 0 0 10px 0;
}
.AnchorArea .form-group .form-control {
  height: auto;
  padding: 10px 10px 10px 15px;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
}

.UploadBox .Upload {
  border: 2px dashed #b9b9b9;
  padding: 37px 0;
  border-radius: 8px;
  position: relative;
  display: inline-block;
  width: 160px;
  height: 135px;
  text-align: center;
  background-color: #fff;
}
.UploadBox .Upload i {
  font-size: 24px;
}
.UploadBox .Upload span {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  /* text-transform: capitalize; */
  display: block;
}
.UploadBox .Upload input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.UploadBox figure {
  margin: 0 auto 0;
  width: 170px;
}

.newBtn {
  margin-top: 10px;
  display: inline-block;
}
.newBtn a {
  display: inline-block;
}
.newBtn .active {
  background: white;
  color: black;
  border: 1px solid #000;
}

.newBtn1 {
  display: flex;
  justify-content: space-between;
}
.InformationBox {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ddd;
  margin: 10px 0px 10px 0px;
}
.InformationBox h4 {
  font-size: 18px;
  margin: 0 0 10px 0;
  font-weight: 700;
  color: #000;
}
.InformationBox h4 a {
  float: right;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  text-decoration: underline;
}
.InformationBox .ProfileBox {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
}
.InformationBox .ProfileBox figure {
  margin: 0;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  overflow: hidden;
}
.InformationBox .ProfileBox figcaption {
  width: calc(100% - 85px);
  padding: 0 0 0 15px;
}
.InformationBox .ProfileBox figcaption h5 {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin: 0 0 10px 0;
}
.InformationBox .ProfileBox figcaption p {
  margin: 0 0 10px 0;
}
.InformationBox .ProfileBox figcaption p:last-child {
  margin: 0;
}
.InformationBox .ProfileBox figcaption p strong {
  font-weight: 600;
  text-transform: capitalize;
  width: 140px;
  display: inline-block;
  position: relative;
  margin: 0 15px 0 0;
  padding: 0 10px 0 0;
  color: #000;
  font-size: 13px;
  font-weight: 700;
}
.InformationBox .ProfileBox figcaption p strong:after {
  content: ":";
  position: absolute;
  right: 0;
  top: 0;
}
.InformationBox .ProfileBox figcaption p span {
  font-weight: 500;
  text-transform: capitalize;
  color: #5e5c5c;
  font-size: 13px;
  display: inline-block;
}

.InformationBox .Information {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
}
.InformationBox .Information + .Information {
  margin-top: 20px;
}
.InformationBox .Information article {
  display: flex;
}
.InformationBox .Information article aside {
  width: 100%;
  padding: 0 20px 0 0;
}
.InformationBox .Information article aside p {
  margin: 0 0 15px 0;
  display: flex;
}
.InformationBox .Information article aside p:last-child {
  margin: 0;
}
.InformationBox .Information article aside p strong {
  text-transform: capitalize;
  width: 190px;
  display: inline-block;
  position: relative;
  margin: 0 15px 0 0;
  padding: 0 10px 0 0;
  color: #000;
  font-size: 13px;
  font-weight: 700;
}
.InformationBox .Information article aside p strong:after {
  content: ":";
  position: absolute;
  right: 0;
  top: 0;
}
.InformationBox .Information article aside p span {
  font-weight: 600;
  color: #5e5c5c;
  font-size: 13px;
  display: inline-block;
  width: calc(100% - 205px);
  line-height: 24px;
}
.InformationBox .Information.InformationDocumnet article {
  flex-wrap: wrap;
}
.InformationBox .Information.InformationDocumnet article aside {
  padding: 0;
}

.InformationBox .Activities {
}
.InformationBox .Activities ol {
}
.InformationBox .Activities ol li {
  border: 1px solid #ddd;
  padding: 20px;
  margin: 5px 0px 5px 0px;
  border-radius: 8px;
}
.InformationBox .Activities ol li p {
  font-size: 14px;
  font-weight: 500;
}

.DocumnetBox {
  display: flex;
}
.DocumnetBox figcaption {
  box-shadow: 0 0 6px #ddd;
  width: 150px;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 20px 0 0;
  background: #fff;
}
.DocumnetBox figcaption span {
  display: block;
}
.DocumnetBox figcaption label {
  display: block;
  margin: 0;
  text-align: center;
  padding: 10px 0;
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.TopTittle {
  background: white;
  padding: 10px;
  border-radius: 6px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.TopTittle h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 700;
  color: #000;
  padding: 4px;
}
.CommonLinks {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.CommonLinks ul li {
  margin: 0 10px 0 0;
  display: inline-block;
}
.CommonLinks ul li.active a {
  background-image: linear-gradient(45deg, #12aab4, #12aab4);
  color: #fff;
}
.CommonLinks ul li a {
  background-color: #d0cece;
  border-radius: 5px;
  padding: 10px 20px;
  border: none !important;
  color: #000;
  font-weight: 600;
  cursor: pointer;
  text-transform: capitalize;
  margin: 0;
  font-size: 15px;
  display: block;
}

.TableList table tr td .form-group {
  position: relative;
  margin: 0;
}
.TableList table tr td .form-group .form-control {
  height: auto;
  padding: 10px 10px 10px 15px;
  border: 2px solid #ededed;
  border-radius: 5px;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
}

.TableFinance th {
  width: 300px;
}

.AnchorFatchData {
}
.AnchorFatchData ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AnchorFatchData ul li {
}
.AnchorFatchData ul li .form-group {
  width: 400px;
}
.AnchorFatchData ul li p {
  font-size: 15px;
  color: red;
  font-weight: 700;
}
.AnchorFatchData ul li span {
}

.ModalBox .modal-dialog .Category {
  padding: 0;
}
.ModalBox .modal-dialog .Category h3 {
  text-align: center;
  font-weight: 800;
  margin: 0 0 25px 0;
  border-bottom: 1px solid #ddd;
  padding: 0 0 15px;
  font-size: 18px;
}
.ModalBox .modal-dialog .Category h3 span {
  display: block;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
}

.ModalBox .modal-dialog .Category h5 {
  font-size: 16px;
  color: #777;
  text-align: left;
}
.ModalBox .modal-dialog .Category h4 {
  margin: 15px 0 0 0;
  text-align: right;
}
.ModalBox .modal-dialog .Category h4 a {
  text-transform: capitalize;
  margin: 0 0 0 30px;
  font-size: 17px;
  color: #49a0d5;
  font-weight: 400;
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
}

.ModalBox .modal-dialog .Category .form-group {
  margin: 0 0 35px;
  position: relative;
}
.ModalBox .modal-dialog .Category .form-group label {
  display: inline-block;
  text-transform: capitalize;
  font-weight: 500;
  color: #000;
  font-size: 13px;
  background-color: #fff;
  margin: 0 0 -10px 0;
  padding: 6px 10px;
  position: absolute;
  top: -17px;
  left: 10px;
  border-radius: 5px;
  z-index: 1;
  font-family: "Roboto";
}
.ModalBox .modal-dialog .Category .form-group .form-control {
  box-shadow: none;
  border: 1px solid #c3c3c3;
  height: auto;
  font-size: 14px;
  color: #000;
  line-height: 22px;
  font-family: "Roboto";
  padding: 15px 15px 10px;
}
.ModalBox .modal-dialog .Category p {
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 20px;
}
.ModalBox .modal-dialog .Category figure {
  text-align: center;
}
.ModalBox .modal-dialog .CloseModal {
  position: absolute;
  top: -13px;
  right: -13px;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #000;
  font-size: 15px;
  border-radius: 50%;
}

.AdminAccess table {
  border: 1px solid #e1e1e1;
}
.AdminAccess table tr th {
  background-color: #e1e1e1;
  font-weight: 700;
  color: #000;
  font-size: 14px;
  padding: 10px 20px;
  border: none;
  border-right: 1px solid #bbb;
}
.AdminAccess table tr td {
  font-weight: 600;
  color: #404040;
  padding: 10px 20px;
  font-size: 13px;
  border-bottom: 1px solid #ddd;
  border-top: none;
  vertical-align: middle;
  border-right: 1px solid #ddd;
}
.AdminAccess table tr td .Read {
  display: inline-block;
  position: relative;
}
.AdminAccess table tr td .Read input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.AdminAccess table tr td .Read span {
  font-size: 16px;
  color: #cdcdcd;
}
.AdminAccess table tr td .Read input:checked ~ span {
  color: #0eaab4;
}
.AdminAccess table tr td .Read input:checked ~ span i:before {
  content: "\f070";
}
.AdminAccess table tr td .Access {
  display: inline-block;
  position: relative;
}
.AdminAccess table tr td .Access input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.AdminAccess table tr td .Access span {
  font-size: 18px;
  color: #cdcdcd;
}
.AdminAccess table tr td .Access input:checked ~ span {
  color: #0eaab4;
}
.AdminAccess table tr td .Access input:checked ~ span i:before {
  content: "\f13e";
}
.AdminAccess table tr td strong {
  font-weight: 600;
  color: #000;
}

.PasswordBox {
  /* background-color:#f3f3f3; */ /* padding:20px; */ /* border-radius:8px; */
  max-width: 575px; /* border:1px solid #ddd; */
}
.PasswordBox aside {
  margin: 0 0 15px 0;
}
.PasswordBox aside h4 {
  font-weight: 700;
  font-size: 20px;
}
.PasswordBox article {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ddd;
}

.Eye {
  position: absolute;
  right: 15px;
  top: 43px;
}

.CommonTabs {
  margin: 0 0 15px 0;
}
.CommonTabs .nav-tabs {
  border: none;
  margin: 0;
}
.CommonTabs .nav-tabs li {
  margin: 0 15px 0 0;
}
.CommonTabs .nav-tabs li a {
  background-color: #d0cece;
  border-radius: 5px;
  padding: 10px 35px;
  border: none;
  color: #000;
  font-weight: 600;
  cursor: pointer;
  text-transform: capitalize;
  margin: 0;
  font-size: 15px;
  display: inline-block;
}
.CommonTabs .nav-tabs li a.active {
  background-color: #0eaab4;
  color: #fff;
}

.AccordionBox .card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px #ddd;
  margin: 0 0 30px 0;
  border: none;
  overflow: hidden;
}
.AccordionBox .card .card-header {
  border: none;
  background-color: transparent;
  padding: 20px 50px 20px 20px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AccordionBox .card .card-header:before {
  content: "\f106";
  font-family: "FontAwesome";
  border: none;
  margin-left: 0;
  font-weight: 700;
  font-size: 22px;
  line-height: 10px;
  position: absolute;
  top: 32px;
  right: 20px;
  transition: 0.5s all ease-in-out;
}
.AccordionBox .card .card-header[aria-expanded="false"]:before {
  transform: rotate(180deg);
}
.AccordionBox .card .card-header h4 {
  font-weight: 600;
  font-size: 17px;
  text-transform: capitalize;
}
.AccordionBox .card .card-header a {
  color: black;
  border: 1px solid #ddd;
  display: inline-block;
  border-radius: 20px;
  padding: 5px 15px;
  margin-left: 10px;
}
.AccordionBox .card .card-body {
  padding: 18px;
  border-top: 1px solid #ddd;
}

.perameter {
  font-size: 20px;
  font-weight: 700;
  color: black;
  margin-bottom: 10px;
  margin-top: 20px;
}

.itaalic {
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  margin-bottom: 10px;
}
.backArrow {
  margin: 0px 8px 0px 0px;
}

.anchor-view-access {
  background-color: #ffffff;
  margin: 0 0 20px 0;
  padding: 20px 30px;
  border: 1px solid #ddd;
  border-radius: 10px;
  align-items: center;
}
.anchor-view-access h3 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin: 0 0 7px 0;
}
.anchor-view-access ul li {
  font-size: 14px;
  font-weight: 500;
  line-height: 38px;
}

.anchorCompany {
}
.anchorCompany ul {
}
.anchorCompany ul li {
  margin: 0px 0px 20px 0px;
}
.anchorCompany label {
}
.anchorPergraph {
  height: auto;
  padding: 10px 10px 10px 15px;
  border: 2px solid #ededed;
  border-radius: 5px;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  min-width: 400px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}
.anchorCompany h3 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin: 0 0 7px 0;
}

.anchor-view-heading {
  display: flex;
  justify-content: space-between;
}

.idCard {
  text-align: center;
  margin-top: 7px;
  font-size: 12px;
  font-weight: 700;
}

.UploadBox .Upload img{
  width: 140px;
  text-align: center;
  position: absolute;
  top: 18px;
  left: 10px;
  max-height: 110px;
}
.DocumnetBox figcaption label{background: #ddd;}
.DocumnetBox figcaption span {
  display: block;
  min-height: 120px;
  padding: 10px;
  text-align: center;
}
.DocumnetBox figcaption span img {
  max-height: 110px;
  text-align: center;
}

.DocumnetBox figcaption span a{
  display: block;
    min-height: 110px;
    padding: 10px;
    text-align: center;
}
.DocumnetBox figcaption span a img{width: 60%;}

.Actions .CheckBox .checkmark {min-width: 24px!important;}
.Actions .CheckBox .checkmark {margin-left: 10px;}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}